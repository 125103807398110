<template>
  <div class="banner-container h-100">
    <img :src="DarkUrl" class="logo" width="400" v-if="isDark" />
    <img :src="LightUrl" class="logo" width="400" v-else />
    <div class="content">
      <slot></slot>
    </div>
    <div class="banner"></div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useTheme } from 'vuetify'
const theme = useTheme()
const isDark = computed(() => theme.global.current.value.dark)

import DarkUrl from '@/../assets/title-logo-dark.svg?url'
import LightUrl from '@/../assets/title-logo-light.svg?url'

</script>
<style lang="scss">
.banner-container {
  display: flex;
  flex-direction: row;

  .banner {
    flex: 1;
    background: url('/assets/banner.svg') no-repeat center center;
  }

  .content {
    flex: 1;
  }
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  padding: 60px;
}
</style>
